<script setup lang="ts">
const { t } = useI18n();
const emit = defineEmits(["logout", "close"]);
const appConfig = useRuntimeConfig().public;

const hasClips = appConfig.APP_CUSTOMER.web_sdk.enable_clipping;
</script>

<template>
  <ul>
    <li class="my-6 lg:px-6">
      <BaseButton
        class="text-link flex items-center !leading-[21px] gap-3"
        type="icon"
        tag="nuxt-link"
        name="header-account-link"
        :to="{ path: '/account/general/' }"
        @click.capture="emit('close')"
      >
        <IconHeaderAccount class="text-[var(--base-icon-color)]" />
        <span class="text-[var(--base-secondary-text-color)] truncate">{{ t('labels.account') }}</span>
      </BaseButton>
    </li>
    <li class="my-6 lg:px-6">
      <BaseButton
        class="text-link flex items-center !leading-[21px] gap-3"
        type="icon"
        tag="nuxt-link"
        name="header-following-link"
        :to="{ path: '/following/' }"
        @click.capture="emit('close')"
      >
        <IconHeaderManageFollowing class="text-[var(--base-icon-color)]" />
        <span class="text-[var(--base-secondary-text-color)] truncate">{{
          t('labels.manage_following')
        }}</span>
      </BaseButton>
    </li>
    <li class="my-6 lg:px-6">
      <BaseButton
        class="text-link flex items-center !leading-[21px] gap-3"
        type="icon"
        tag="nuxt-link"
        name="header-videos-link"
        :to="{ path: '/selected/videos/' }"
        @click.capture="emit('close')"
      >
        <IconHeaderSelectedVideos class="text-[var(--base-icon-color)]" />
        <span class="text-[var(--base-secondary-text-color)] truncate">{{ t('labels.selected_videos') }}</span>
      </BaseButton>
    </li>
    <li v-if="hasClips" class="my-6 lg:px-6">
      <BaseButton
        class="text-link flex items-center !leading-[21px] gap-3"
        type="icon"
        tag="nuxt-link"
        name="header-clips-link"
        :to="{ path: '/selected/clips/' }"
        @click.capture="emit('close')"
      >
        <IconHeaderMyClipsHighlights class="text-[var(--base-icon-color)]" />
        <span class="text-[var(--base-secondary-text-color)] truncate">{{
          t('labels.my_clips_and_highlights')
        }}</span>
      </BaseButton>
    </li>
    <li class="my-6 lg:px-6">
      <BaseButton
        class="text-secondary text-link flex items-center !leading-[21px] gap-3"
        type="icon"
        name="header-logout-button"
        @click.prevent="emit('logout')"
      >
        <IconHeaderLogout />
        <span class="text-error">{{ t('labels.logout') }}</span>
      </BaseButton>
    </li>
    <slot name="bottom" />
  </ul>
</template>
