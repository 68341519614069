<script setup lang="ts">
export interface UserMenuProps {
  fullName?: string;
  withThemeSwitcher?: boolean;
}

const { t } = useI18n();
const emit = defineEmits(["logout"]);
const props = defineProps<UserMenuProps>();
const appConfig = useRuntimeConfig().public;

const hasClips = appConfig.APP_CUSTOMER.web_sdk.enable_clipping;
</script>

<template>
  <UMenu>
    <template #trigger="{ toggle }">
      <BaseButton name="user-menu" type="icon" title="Menu" @click="toggle">
        <IconLogoInitials v-if="props.fullName" :title="props.fullName" />
      </BaseButton>
    </template>
    <template #default>
      <div v-auto-animate class="w-[18rem] bg-white dark:bg-neutral-dark-700">
        <UList>
          <UListItem size="md">
            <BaseButton
              class="text-link w-full flex items-center !leading-[21px] gap-3"
              type="icon"
              tag="nuxt-link"
              name="header-account-link"
              :to="{ path: '/account/general/' }"
            >
              <IconHeaderAccount class="text-[var(--base-icon-color)]" />
              <span class="text-[var(--base-secondary-text-color)] truncate">{{ t('labels.account') }}</span>
            </BaseButton>
          </UListItem>
          <UListItem size="md">
            <BaseButton
              class="text-link w-full flex items-center !leading-[21px] gap-3"
              type="icon"
              tag="nuxt-link"
              name="header-following-link"
              :to="{ path: '/following/' }"
            >
              <IconHeaderManageFollowing class="text-[var(--base-icon-color)]" />
              <span class="text-[var(--base-secondary-text-color)] truncate">{{
                t('labels.manage_following')
              }}</span>
            </BaseButton>
          </UListItem>
          <UListItem size="md">
            <BaseButton
              class="text-link w-full flex items-center !leading-[21px] gap-3"
              type="icon"
              tag="nuxt-link"
              name="header-videos-link"
              :to="{ path: '/selected/videos/' }"
            >
              <IconHeaderSelectedVideos class="text-[var(--base-icon-color)]" />
              <span class="text-[var(--base-secondary-text-color)] truncate">{{ t('labels.selected_videos') }}</span>
            </BaseButton>
          </UListItem>
          <UListItem v-if="hasClips" size="md">
            <BaseButton
              class="text-link w-full flex items-center !leading-[21px] gap-3"
              type="icon"
              tag="nuxt-link"
              name="header-clips-link"
              :to="{ path: '/selected/clips/' }"
            >
              <IconHeaderMyClipsHighlights class="text-[var(--base-icon-color)]" />
              <span class="text-[var(--base-secondary-text-color)] truncate">{{
                t('labels.my_clips_and_highlights')
              }}</span>
            </BaseButton>
          </UListItem>
          <UListItem size="md">
            <BaseButton
              class="text-secondary w-full text-link flex items-center !leading-[21px] gap-3"
              type="icon"
              name="header-logout-button"
              @click.prevent="emit('logout')"
            >
              <IconHeaderLogout />
              <span class="text-error">{{ t('labels.logout') }}</span>
            </BaseButton>
          </UListItem>
          <template v-if="withThemeSwitcher">
            <UDivider lighter />
            <div class="flex items-center justify-center w-full pt-4 pb-2">
              <BaseThemeSwitcher has-labels />
            </div>
          </template>
        </UList>
      </div>
    </template>
  </UMenu>
</template>
