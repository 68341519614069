<script setup lang="ts">
export interface IconChevronRightProps {
  width?: number;
  height?: number;
}

const props = withDefaults(defineProps<IconChevronRightProps>(), {
  width: 12,
  height: 12,
});
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12.756 2.103 15l7.875-8.104L17.852 15 20 12.802 9.978 2.5z" fill="currentColor" fill-rule="evenodd" />
  </svg>
</template>
