<script setup lang="ts">
import { useScroll } from "@vueuse/core";
import { FALLBACK } from "~/constants";
import HeaderAPI from "~/services/header";

const el = ref<HTMLElement | null>(null);
const { y: windowY } = useScroll(el);
const { data: customerAuthSlides } = await useFetch<ICustomerAuthSlide[]>("/json/customer/auth_slides.json");
const canShownHeader = computed(() => windowY.value > 50);

onMounted(() => {
  HeaderAPI.clearCachedLinks();
});

provide("authLayoutData", { canShownHeader });
</script>

<template>
  <div class="authLayout">
    <div v-if="customerAuthSlides?.length" class="authLayout__slider">
      <BaseSlider
        :slides="customerAuthSlides"
        has-indicator
        has-autoplay
        has-image-mask
        :fallback-variant="FALLBACK.VERTICAL_BANNER"
      />
    </div>
    <div ref="el" class="authLayout__content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.authLayout {
  @apply md:h-screen flex items-start;

  .authLayout__slider {
    @apply hidden lg:flex h-full md:aspect-[1/2] xl:aspect-[5/8];

  }
  .authLayout__content {
    @apply h-screen w-full overflow-y-auto;

    background-color: var(--base-background-color);
  }
}
</style>
