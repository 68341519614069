<script setup lang="ts">
import type { NuxtLinkProps } from "#app";
import { NuxtLink } from "#components";
import isEqual from "lodash-es/isEqual";

const props = defineProps<
  NuxtLinkProps & {
    as?: string | Component;
    type?: HTMLButtonElement["type"];
    disabled?: boolean;
    exact?: boolean;
    exactQuery?: boolean;
    exactHash?: boolean;
    inactiveClass?: string;
  }
>();

function resolveLinkClass(
  route: any,
  $route: any,
  { isActive, isExactActive }: { isActive: boolean; isExactActive: boolean },
) {
  if (props.exactQuery && !isEqual(route.query, $route.query))
    return props.inactiveClass;

  if (props.exactHash && route.hash !== $route.hash)
    return props.inactiveClass;

  if (props.exact && isExactActive)
    return props.activeClass;

  if (!props.exact && isActive)
    return props.activeClass;

  return props.inactiveClass;
}
</script>

<template>
  <Component
    :is="props.as || 'button'"
    v-if="!props.to && !props.href"
    v-bind="$attrs"
    :type="props.type"
    :disabled="props.disabled ? 'true' : undefined"
    :class="props.inactiveClass"
  >
    <slot />
  </Component>
  <NuxtLink
    v-else
    v-slot="{ route, $route, href, target, rel, navigate, isActive, isExactActive, isExternal }"
    v-bind="props"
    custom
  >
    <a
      v-bind="$attrs"
      :href="!props.disabled ? href : undefined"
      :disabled="props.disabled ? 'true' : undefined"
      :aria-disabled="props.disabled ? 'true' : undefined"
      :role="props.disabled ? 'link' : undefined"
      :rel="rel"
      :target="target"
      :class="resolveLinkClass(route, $route, { isActive, isExactActive })"
      @click="(e) => !isExternal && !props.disabled && navigate(e)"
    >
      <slot v-bind="{ isActive: exact ? isExactActive : isActive }" />
    </a>
  </NuxtLink>
</template>
