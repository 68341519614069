
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as aboutdraYrkEObeMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/about.vue?macro=true";
import { default as indexFyGXmGw6gEMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing/index.vue?macro=true";
import { default as billingMOIL4gBFEVMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing.vue?macro=true";
import { default as devicesOMQPR2ZQ3RMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/devices.vue?macro=true";
import { default as generaluWxV1h8FsgMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/general.vue?macro=true";
import { default as indexRtHgYQnpE8Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/index.vue?macro=true";
import { default as passwordCPEYbKiLtQMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/password.vue?macro=true";
import { default as _91id_93zlbohoMXieMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/[id].vue?macro=true";
import { default as indextZO1FBVubNMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/index.vue?macro=true";
import { default as ppvpFhmcUaNQuMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv.vue?macro=true";
import { default as _91id_9346ihf0RgdGMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/[id].vue?macro=true";
import { default as _91id_93aoKZ3MYhPXMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/buy/[id].vue?macro=true";
import { default as indexGncTrZ6I8mMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/index.vue?macro=true";
import { default as subscriptionGK0H4nT9kSMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription.vue?macro=true";
import { default as account3QhAHyLpsTMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account.vue?macro=true";
import { default as archiveagv6Md1HtmMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/archive.vue?macro=true";
import { default as indexyCZSksEIAdMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/index.vue?macro=true";
import { default as limitNe94pxrS4sMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/limit.vue?macro=true";
import { default as _91provider_93KO9aHETKvqMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/oauth2/callback/[provider].vue?macro=true";
import { default as forgotPhraeHcXZBMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/forgot.vue?macro=true";
import { default as resetZUtNOoQCvAMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/reset.vue?macro=true";
import { default as indexerWbk1cPT1Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/index.vue?macro=true";
import { default as localqFLW4jpcsnMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/local.vue?macro=true";
import { default as _91id_93sf6X36NXx7Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/buy/[id].vue?macro=true";
import { default as indexGzyaFp4lDlMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/code/index.vue?macro=true";
import { default as indexzheoBa6kytMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/index.vue?macro=true";
import { default as indexzFgmX1hiL4Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/index.vue?macro=true";
import { default as _91subId_93boNKH4MYXtMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/sub-category/[subId].vue?macro=true";
import { default as _91eventId_93hpmr16FfV6Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/channels/[eventId].vue?macro=true";
import { default as clubp6uHnNJs0CMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/club.vue?macro=true";
import { default as _91eventId_93Gt52yYUXLsMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/[eventId].vue?macro=true";
import { default as _91eventId_93RH99X71Gi6Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/stats/[eventId].vue?macro=true";
import { default as faqGSte9SsT6iMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/faq.vue?macro=true";
import { default as followedVWOSJWEFvUMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/followed.vue?macro=true";
import { default as indextSbAy0AAqeMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/index.vue?macro=true";
import { default as followedmn0BD1umiyMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/followed.vue?macro=true";
import { default as indexnIsWNkWcJ9Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/index.vue?macro=true";
import { default as indexBfqRWuY7wOMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/index.vue?macro=true";
import { default as followedqBvXcSKB5HMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/followed.vue?macro=true";
import { default as indexvQrLsgg37KMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/index.vue?macro=true";
import { default as followedFOB5uix5euMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/followed.vue?macro=true";
import { default as indexNNtfK3bXBTMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/index.vue?macro=true";
import { default as highlightQbdm5f6gTbMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/highlight.vue?macro=true";
import { default as homeemZ3sW7FtDMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/home.vue?macro=true";
import { default as indexKb1RKZykycMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/index.vue?macro=true";
import { default as _91type_93111hFqEuygMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/info/[type].vue?macro=true";
import { default as latestNK3GtIVxiZMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/latest.vue?macro=true";
import { default as _91leagueId_93WP3xOzdbC0Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/league/[leagueId].vue?macro=true";
import { default as indexy61AWBGmN8Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live/[sportType]/index.vue?macro=true";
import { default as _91sportType_93AzMUtKCh8wMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live/[sportType].vue?macro=true";
import { default as index64hMoMCx9tMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live/index.vue?macro=true";
import { default as open_45appI3ro9r9FlcMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/open-app.vue?macro=true";
import { default as failureWMQwTcioRmMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/failure.vue?macro=true";
import { default as processing3kizEWGEOlMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/processing.vue?macro=true";
import { default as successt5K9xuFKVVMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/success.vue?macro=true";
import { default as privacyXCV1lppPu4Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/privacy.vue?macro=true";
import { default as recommendationBzugptqcd5Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/recommendation.vue?macro=true";
import { default as _91referenceId_934RS158WUVoMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/redirect/events/[referenceId].vue?macro=true";
import { default as search1eBKK12ZypMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/search.vue?macro=true";
import { default as clips8xi9yJalyIMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/clips.vue?macro=true";
import { default as videosdTfMKbypxjMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/videos.vue?macro=true";
import { default as suggestedkD9v2Gz7UaMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/suggested.vue?macro=true";
import { default as _91teamId_93StowxngtjOMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/team/[teamId].vue?macro=true";
import { default as termsb3M4kNwponMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/terms.vue?macro=true";
import { default as upcomingwmoZ03iIERMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/upcoming.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutdraYrkEObeMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/about.vue")
  },
  {
    name: account3QhAHyLpsTMeta?.name,
    path: "/account",
    meta: account3QhAHyLpsTMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account.vue"),
    children: [
  {
    name: billingMOIL4gBFEVMeta?.name,
    path: "billing",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing.vue"),
    children: [
  {
    name: "account-billing",
    path: "",
    meta: indexFyGXmGw6gEMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing/index.vue")
  }
]
  },
  {
    name: "account-devices",
    path: "devices",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/devices.vue")
  },
  {
    name: "account-general",
    path: "general",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/general.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/index.vue")
  },
  {
    name: "account-password",
    path: "password",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/password.vue")
  },
  {
    name: ppvpFhmcUaNQuMeta?.name,
    path: "ppv",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv.vue"),
    children: [
  {
    name: "account-ppv-id",
    path: ":id()",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/[id].vue")
  },
  {
    name: "account-ppv",
    path: "",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/index.vue")
  }
]
  },
  {
    name: subscriptionGK0H4nT9kSMeta?.name,
    path: "subscription",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription.vue"),
    children: [
  {
    name: "account-subscription-id",
    path: ":id()",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/[id].vue")
  },
  {
    name: "account-subscription-buy-id",
    path: "buy/:id()",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/buy/[id].vue")
  },
  {
    name: "account-subscription",
    path: "",
    meta: indexGncTrZ6I8mMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/index.vue")
  }
]
  }
]
  },
  {
    name: "archive",
    path: "/archive",
    meta: archiveagv6Md1HtmMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/archive.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: indexyCZSksEIAdMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/index.vue")
  },
  {
    name: "auth-login-limit",
    path: "/auth/login/limit",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/limit.vue")
  },
  {
    name: "auth-oauth2-callback-provider",
    path: "/auth/oauth2/callback/:provider()",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/oauth2/callback/[provider].vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/reset.vue")
  },
  {
    name: "auth-sign-up",
    path: "/auth/sign-up",
    meta: indexerWbk1cPT1Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/index.vue")
  },
  {
    name: "auth-sign-up-local",
    path: "/auth/sign-up/local",
    meta: localqFLW4jpcsnMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/local.vue")
  },
  {
    name: "auth-sign-up-subscription-buy-id",
    path: "/auth/sign-up/subscription/buy/:id()",
    meta: _91id_93sf6X36NXx7Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/buy/[id].vue")
  },
  {
    name: "auth-sign-up-subscription-code",
    path: "/auth/sign-up/subscription/code",
    meta: indexGzyaFp4lDlMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/code/index.vue")
  },
  {
    name: "auth-sign-up-subscription",
    path: "/auth/sign-up/subscription",
    meta: indexzheoBa6kytMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/index.vue")
  },
  {
    name: "category-mainId",
    path: "/category/:mainId()",
    meta: indexzFgmX1hiL4Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/index.vue")
  },
  {
    name: "category-mainId-sub-category-subId",
    path: "/category/:mainId()/sub-category/:subId()",
    meta: _91subId_93boNKH4MYXtMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/sub-category/[subId].vue")
  },
  {
    name: "channels-eventId",
    path: "/channels/:eventId()",
    meta: _91eventId_93hpmr16FfV6Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/channels/[eventId].vue")
  },
  {
    name: "club",
    path: "/club",
    meta: clubp6uHnNJs0CMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/club.vue")
  },
  {
    name: "events-eventId",
    path: "/events/:eventId()",
    meta: _91eventId_93Gt52yYUXLsMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/[eventId].vue")
  },
  {
    name: "events-stats-eventId",
    path: "/events/stats/:eventId()",
    meta: _91eventId_93RH99X71Gi6Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/stats/[eventId].vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqGSte9SsT6iMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/faq.vue")
  },
  {
    name: "following-category-mainId-followed",
    path: "/following/category/:mainId()/followed",
    meta: followedVWOSJWEFvUMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/followed.vue")
  },
  {
    name: "following-category-mainId",
    path: "/following/category/:mainId()",
    meta: indextSbAy0AAqeMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/index.vue")
  },
  {
    name: "following-category-followed",
    path: "/following/category/followed",
    meta: followedmn0BD1umiyMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/followed.vue")
  },
  {
    name: "following-category",
    path: "/following/category",
    meta: indexnIsWNkWcJ9Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/index.vue")
  },
  {
    name: "following",
    path: "/following",
    meta: indexBfqRWuY7wOMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/index.vue")
  },
  {
    name: "following-league-leagueId-followed",
    path: "/following/league/:leagueId()/followed",
    meta: followedqBvXcSKB5HMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/followed.vue")
  },
  {
    name: "following-league-leagueId",
    path: "/following/league/:leagueId()",
    meta: indexvQrLsgg37KMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/index.vue")
  },
  {
    name: "following-league-followed",
    path: "/following/league/followed",
    meta: followedFOB5uix5euMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/followed.vue")
  },
  {
    name: "following-league",
    path: "/following/league",
    meta: indexNNtfK3bXBTMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/index.vue")
  },
  {
    name: "highlight",
    path: "/highlight",
    meta: highlightQbdm5f6gTbMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/highlight.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexKb1RKZykycMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/index.vue")
  },
  {
    name: "info-type",
    path: "/info/:type()",
    meta: _91type_93111hFqEuygMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/info/[type].vue")
  },
  {
    name: "latest",
    path: "/latest",
    meta: latestNK3GtIVxiZMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/latest.vue")
  },
  {
    name: "league-leagueId",
    path: "/league/:leagueId()",
    meta: _91leagueId_93WP3xOzdbC0Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/league/[leagueId].vue")
  },
  {
    name: _91sportType_93AzMUtKCh8wMeta?.name,
    path: "/live/:sportType()",
    meta: _91sportType_93AzMUtKCh8wMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live/[sportType].vue"),
    children: [
  {
    name: "live-sportType",
    path: "",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live/[sportType]/index.vue")
  }
]
  },
  {
    name: "live",
    path: "/live",
    meta: index64hMoMCx9tMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live/index.vue")
  },
  {
    name: "open-app",
    path: "/open-app",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/open-app.vue")
  },
  {
    name: "payment-failure",
    path: "/payment/failure",
    meta: failureWMQwTcioRmMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/failure.vue")
  },
  {
    name: "payment-processing",
    path: "/payment/processing",
    meta: processing3kizEWGEOlMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/processing.vue")
  },
  {
    name: "payment-success",
    path: "/payment/success",
    meta: successt5K9xuFKVVMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/success.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyXCV1lppPu4Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/privacy.vue")
  },
  {
    name: "recommendation",
    path: "/recommendation",
    meta: recommendationBzugptqcd5Meta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/recommendation.vue")
  },
  {
    name: "redirect-events-referenceId",
    path: "/redirect/events/:referenceId()",
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/redirect/events/[referenceId].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: search1eBKK12ZypMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/search.vue")
  },
  {
    name: "selected-clips",
    path: "/selected/clips",
    meta: clips8xi9yJalyIMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/clips.vue")
  },
  {
    name: "selected-videos",
    path: "/selected/videos",
    meta: videosdTfMKbypxjMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/videos.vue")
  },
  {
    name: "suggested",
    path: "/suggested",
    meta: suggestedkD9v2Gz7UaMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/suggested.vue")
  },
  {
    name: "team-teamId",
    path: "/team/:teamId()",
    meta: _91teamId_93StowxngtjOMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/team/[teamId].vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsb3M4kNwponMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/terms.vue")
  },
  {
    name: "upcoming",
    path: "/upcoming",
    meta: upcomingwmoZ03iIERMeta || {},
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/upcoming.vue")
  }
]