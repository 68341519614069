<script setup lang="ts">
export interface IconCheck {
  fill?: string;
  width?: number;
  height?: number;
}
const props = withDefaults(defineProps<IconCheck>(), { fill: "var(--base-primary-color)", width: 20, height: 20 });
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path :fill="props.fill" d="M20 4.026 17.97 2 6.977 12.97 2.03 8.034 0 10.06 6.958 17z" fill-rule="evenodd" />
  </svg>
</template>
